import { log } from './shared/utils/logging/logger';
import React from 'react'
import ReactDom from 'react-dom'
import App from './App'

const logger = log.extend('index')

// declare var CONFIG: Config
logger(CONFIG)

if (CONFIG.logging.logRocket) {
  const initLogRocket = require('./shared/utils/logging/logRocket').initLogRocket
  initLogRocket()
}

ReactDom.render(<App />, document.getElementById('root'))
